import React, {useState, useEffect} from 'react';
import Loader from './components/loader';
import Home from './views/Home';

const App = () => {
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    if(loading){
      setTimeout(() => {
        //setLoading(prev => !prev)
      }, 3000);
    }
  },[loading]) 

  if(loading){
    return <Loader />
  }
  return (
    <div className="App">
      <Home />
    </div>
  );
}

export default App;
