import React from "react";
import "./loader.scss"

const Loader = () => {
    return <div className="loader place-content-center">
        <div className="loader-bg relative">
            <div className="left vertical-line blue-line-v absolute top-10 left-5"></div>
            <div className="left horizontal-line blue-line-h absolute top-10 left-5"></div>

            <div className="right vertical-line blue-line-v absolute top-10 right-5"></div>
            <div className="right horizontal-line blue-line-h absolute top-10 right-5"></div>

            <div className="left vertical-line pink-line-v absolute bottom-10 left-5"></div>
            <div className="left horizontal-line pink-line-h absolute bottom-10 left-5"></div>

            <div className="right vertical-line pink-line-v absolute bottom-10 right-5"></div>
            <div className="right horizontal-line pink-line-h absolute bottom-10 right-5"></div>
            

            <img className="loading-logo block mx-auto" src="../../images/falta-mucho-para-mañana.jpg" />
            <div className="loader-container flex justify-center mt-40">
                <div className="rectangle-1"></div>
                <div className="rectangle-2"></div>
                <div className="rectangle-3"></div>
                <div className="rectangle-4"></div>
                <div className="rectangle-5"></div>
                <div className="rectangle-6"></div>
                <div className="rectangle-5"></div>
                <div className="rectangle-4"></div>
                <div className="rectangle-3"></div>
                <div className="rectangle-2"></div>
                <div className="rectangle-1"></div>
            </div>
        </div>
    </div>
}

export default Loader